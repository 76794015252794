import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";

Vue.use(Router);

/* 防止重复路由点击报错 */
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const constantRoutes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    name: "home",
    children: [
      {
        path: "home",
        component: () => import("@/view/index/home"),
        name: "index",
        meta: { title: "首页" },
      },
    ],
  },

  {
    path: "/consult",
    component: Layout,
    children: [
      {
        path: "consult",
        component: () => import("@/view/consult/consult"),
        name: "consult",
        meta: { title: "咨询顾问工具" },
      },
    ],
  },

  {
    path: "/solution",
    component: Layout,
    children: [
      {
        path: "solution",
        component: () => import("@/view/solution/solution"),
        name: "solution",
        meta: { title: "解决方案" },
      },
    ],
  },

  {
    path: "/case",
    component: Layout,
    children: [
      {
        path: "case",
        component: () => import("@/view/case/case"),
        name: "case",
        meta: { title: "行业案列" },
      },
    ],
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];

export const asyncRoutes = [];

const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    // mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
