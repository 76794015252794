<template>
  <div class="" @scroll="getScrollTop">
    <el-container>
      <el-header>
        <!-- 头部 -->
        <HeadBar></HeadBar>
      </el-header>
      <el-container>
        <el-main class="main">
          <!-- 内容区域 -->
          <AppMain></AppMain>

          <div class="floating-window flex-col-center flex-sb">
            <div class="flex-col-center phone-box">
              <div class="flex-col-center" @click="showPhoneFn">
                <img
                  src="../assets/img/common/fuPhone.png"
                  class="icon"
                  alt=""
                />
                <span class="floating-window-text">电话咨询</span>
              </div>
              <div
                class="phone-float animate__animated animate__slideInRight"
                v-if="showPhone"
              >
                <div class="mobile-btn flex flex-item-center">
                  <img
                    class="phone-img"
                    src="../assets/img/common/phone.png"
                    alt=""
                  />
                  <div class="mobile">178 0653 8105</div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="flex-col-center wx-box">
              <div class="flex-col-center" @click="showWxFn">
                <img src="../assets/img/common/fwx.png" class="icon" alt="" />
                <span class="floating-window-text">微信查询</span>
              </div>

              <div
                v-if="showWx"
                class="wx-float flex-col-center animate__animated animate__slideInRight"
              >
                <img
                  class="wx-float-img"
                  src="../assets/img/common/kefu.png"
                  alt=""
                />
                <span>
                  微信扫码<br />
                  添加专属服务顾问咨询
                </span>
              </div>
            </div>
            <div class="line"></div>
            <div class="flex-col-center" @click="goBack">
              <img src="../assets/img/common/back.png" class="icon" alt="" />
              <span class="floating-window-text">返回顶部</span>
            </div>
          </div>
        </el-main>
      </el-container>

      <el-container>
        <el-footer>
          <!-- 底部区域 -->
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeadBar from "./components/HeadBar";
import Footer from "./components/Footer";
import AppMain from "./components/AppMain.vue";
export default {
  name: "Layout",
  components: {
    HeadBar,
    Footer,
    AppMain,
  },
  data() {
    return {
      scrollTop: 0, //默认距离顶部的距离
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认初始值
      showWx: false,
      showPhone: false,
    };
  },
  mounted() {
    const system = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      navigator.userAgent
    )
      ? "h5"
      : "PC";
    if (system === "h5") {
      // 切换二级目录
      let h = document.location.host
      let s = document.location.protocol

      window.location.replace(`${s}://${h}/h5/`);
    }

    this.getScrollTop();

    document.addEventListener("scroll", this.debounce(this.getScrollTop, 100));
  },
  methods: {
    showWxFn() {
      this.showWx = !this.showWx;
    },
    showPhoneFn() {
      this.showPhone = !this.showPhone;
    },

    debounce(fn, delay) {
      let time = false; //第一次进来time为false，走else
      return function () {
        if (time) {
          clearTimeout(time); //滚动条在不断的滚动，我们不断的清除定时器（重新初始化定时器，在重新设置定时器）
          time = setTimeout(fn, delay); //又重新设置定时器，
        } else {
          time = setTimeout(fn, delay); //进来第一次先进入这里，然后time被赋值 != false,如果滚动条在继续滚动，接着马上进入time == true中
        }
      };
    },

    // 返回顶部事件
    goBack() {
      document.documentElement.scrollTop = 0;
      let that = this; // 防止this指向问题
      // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
      if (that.scrollTrigger) {
        return;
      }
      // 获取当前距离顶部的距离
      let scrollTop = this.scrollTop;
      let steep = scrollTop / 500;
      let timer = setInterval(() => {
        that.scrollTrigger = true;
        // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
        scrollTop -= steep;
        // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
        steep += 20;
        if (scrollTop <= 0) {
          clearInterval(timer);
          that.scrollTrigger = false;
        }
        document.documentElement.scrollTop = scrollTop;
      }, 30);
    },
    // 监听滚动条
    getScrollTop(e) {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-main {
  padding: 0;
}

.floating-window {
  .phone-box {
    position: relative;

    .phone-float {
      position: absolute;
      background-color: #ffffff;
      padding: 20px;
      box-shadow: 0px 2px 20px rgba(205, 205, 205, 0.3);
      border-radius: 10px;
      left: -190px;
      top: -10px;
      text-align: center;

      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);

      .mobile-btn {
        width: 130px;
      }
    }

    .phone-img {
      width: 24px;
      height: 24px;
      margin-right: 7px;
    }
  }

  .wx-box {
    position: relative;
    .wx-float {
      position: absolute;
      background-color: #ffffff;
      padding: 30px;
      box-shadow: 0px 2px 20px rgba(205, 205, 205, 0.3);
      border-radius: 10px;
      left: -285px;
      top: -100px;
      text-align: center;
      .wx-float-img {
        width: 200px;
        height: 200px;
      }
    }
  }

  cursor: pointer;
  right: 22px;
  top: 562px;
  box-sizing: border-box;
  padding: 16px 8px;
  position: fixed;
  width: 64px;
  height: 228px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(205, 205, 205, 0.3);

  .icon {
    width: 24px;
    height: 24px;
    margin-bottom: 7px;
  }
  .floating-window-text {
    font-size: 12px;
    font-weight: 400;
    color: rgba(107, 107, 109, 1);
  }

  .line {
    width: 100%;
    border: 1px solid rgba(235, 235, 235, 1);
  }
}
.el-footer {
  padding: 0;
}
</style>
